import React from "react";
import type { GatsbyBrowser } from "gatsby";
import Navbar from "./src/components/navbar";
import Footer from "./src/components/footer";
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");
require("./src/styles/global.css");

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <div id="body">
      <Navbar />
      {element}
      <Footer />
    </div>
  );
};
