import React from "react";
import { StaticImage } from "gatsby-plugin-image";

type LinkProps = {
  to: string;
  label: string;
  className?: string;
};

function Footer() {
  return (
    <footer className="fixed inset-x-0 bottom-0 -z-20 h-[350px] w-full bg-[#222222] text-white">
      <div className="layout-base flex h-full flex-col justify-center gap-8 py-4">
        <div className="flex w-full flex-col gap-8 md:flex-row-reverse md:justify-between">
          <blockquote className="max-w-[500px] flex-1 font-quote text-xl italic opacity-50">
            “Providing honest assessments, feedback and recommendations you can
            trust.”
          </blockquote>
          <StaticImage
            src="../../images/logo.png"
            alt="Response Logo"
            className="h-full w-full shrink-0 grow-0 basis-[fit-content]"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
        <div className="flex flex-col gap-8">
          <a href="tel:8882043833" className="text-[#be382d]">
            (888) 204-3833
          </a>
          <ul>
            <li>5541 Simpson Ave</li>
            <li>Ocean City, NJ 08226</li>
          </ul>
          <p>&copy; 2019 ResponseLearning Corporation (RLC)</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
